<template>
    <div style="height: 100%">
        <a-layout style="height: 100%">
            <a-layout-content style="height: 100%">
                <div class="yj-conten" style="min-height: calc(100% - 48px);background-color: #fff; width: calc(100% - 32px);">
                    <a-card title="转盘互动" style="width: 100%; min-height: 100%" :bordered="false">
                        <a-form-model layout="horizontal" style="width: 800px" ref="ruleForm" :rules="rules" :model="FormModel"
                                      :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">

                            <a-row style='margin:20px 0 0 0'>
                                <a-col :span="24">
                                    <label>互动游戏名称：</label>
                                    <a-input v-model="FormModel.InvitationTitle" :disabled="disabled" />
                                </a-col>
                            </a-row>

                            <a-row style='margin:20px 0 0 0'>
                                <a-col :span="24">
                                    <label>活动时间：</label>
                                    <br />
                                    <a-range-picker :value="Time" @change="handleChange" :disabled="disabled" />
                                </a-col>
                            </a-row>

                            <a-form-model-item label="使用规则" style='margin:20px 0 0 0' labelAlign='left'
                                               :label-col="formItemLayout.labelCol"
                                               :wrapper-col="formItemLayout.wrapperCol">
                                <br />
                                <Rich v-bind:value.sync="FormModel.InvitationRule" style="width:100%;margin-left:-165px;"></Rich>
                            </a-form-model-item>


                            <a-form-model-item label="位置区域" labelAlign='left' :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperColline">
                                <a-switch v-model="FormModel.mapChecked" :disabled="disabled" style="margin-left:-100px">
                                    <a-icon slot="checkedChildren" type="check" />
                                    <a-icon slot="unCheckedChildren" type="close" />
                                </a-switch>
                                <a-button type="primary" value="点击选择" @click="map_display" v-show="FormModel.mapChecked" size="small" style="margin-left: 12px"
                                          :disabled="disabled">点击选择</a-button>
                            </a-form-model-item>
                            <a-form-model-item label="地址" labelAlign='left' v-show="FormModel.mapChecked" :label-col="formItemLayout.labelCol"
                                               :wrapper-col="formItemLayout.wrapperColline">
                                <a-input placeholder="地址" type="textarea" disabled v-model="mapModel.tempaddress" style="margin-left:-120px" />
                            </a-form-model-item>

                            <a-row style='margin:20px 0 0 0'>
                                <a-col :span="24">
                                    <label>抽奖次数：</label>
                                    <a-input v-model="FormModel.InitialLuckDrawCount" :disabled="disabled" type="number" />
                                </a-col>
                            </a-row>

                            <a-row style='margin:20px 0 0 0'>
                                <a-col :span="24">
                                    <label>中奖次数：</label>
                                    <a-input v-model="FormModel.MaxWinningPrizeCount" :disabled="disabled" type="number" />
                                </a-col>
                            </a-row>
                            <a-form-model-item label="助力规则" labelAlign='left' :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperColline">
                                <a-switch v-model="FormModel.ruleChecked" :disabled="disabled" style="margin-left:-100px">
                                    <!--<a-icon slot="checkedChildren" type="check" />
            <a-icon slot="unCheckedChildren" type="close" />-->
                                </a-switch>
                                <div v-show="FormModel.ruleChecked" style="margin-left:-165px;">
                                    <a-row>
                                        <a-col :span="24">
                                            <label>助力1次,被助力者增加：</label>
                                            <a-input v-model="FormModel.HelpLuckDrawCount" :disabled="disabled" type="number" />
                                        </a-col>

                                        <a-col :span="24">
                                            <label>最多助力：</label>
                                            <a-input v-model="FormModel.HelpMaxCount" :disabled="disabled" type="number" />
                                        </a-col>
                                    </a-row>
                                </div>

                            </a-form-model-item>

                            <a-row style='margin:20px 0 0 0'>
                                <a-col :span="24">
                                    <label>邀请团队奖励：</label>
                                    <a-input v-model="FormModel.InviteIntroducerMoney" :disabled="disabled" type="number" />
                                </a-col>
                            </a-row>

                            <a-row style='margin:20px 0 0 0;display:none' > 
                                <a-col :span="24">
                                    <label>邀请分享奖励：</label>
                                    <a-input v-model="FormModel.InviteSharerMoney" :disabled="disabled" type="number" />
                                </a-col>
                            </a-row>

                            <a-row style='margin:20px 0 0 0;display:none'>
                                <a-col :span="24">
                                    <label>裂变类型：</label>
                                    <a-form-item style="margin-bottom:0px;">
                                        <a-form-model-item has-feedback prop="linkman">
                                            <a-select v-model="FormModel.FissionShareType" style="width: 800px">
                                                <a-select-option :value="0" selected="selected">绑定物料</a-select-option>
                                                <a-select-option :value="1">分享裂变</a-select-option>
                                            </a-select>
                                        </a-form-model-item>
                                    </a-form-item>
                                </a-col>
                            </a-row>

                        </a-form-model>

                    </a-card>
                    <a-card :bordered='false' style="margin-left:200px;">
                        <a-button type="primary" :disabled="disabled" @click="gotonext">
                            下一步
                        </a-button>
                    </a-card>
                </div>
            </a-layout-content>
        </a-layout>
      
        <a-modal v-model="mapModelDisplay" title="选择活动区域" centered okText="确定" cancelText="取消" width="900px" height="700px"
                 @ok="confirmAddress">
            &nbsp;<a-input type="text" id="suggestId" v-model="tempaddress" placeholder="请输入地址" />
            <div id="searchResultPanel" style="border:1px solid #C0C0C0;width:150px;height:auto; display:none;"></div>
            <div style="width: 100%; height: auto; margin-top:-40px;">
                <div id="map_title">
                    请以当前
                    <a href="javascript:void(0)" onclick="map_location();" style="color: red; font-weight: 400; font-size: 20px">您的位置</a>为中心，选择本次活动范围。
                    <br />
                    <span>备注：可拖动红色中心标示，改变您当前位置</span>
                </div>
                <div id="map_sel">
                    <a-select :default-value="circlePath.radius" style="width: 120px" @change="radiusChange">
                        <a-select-option value="0">请选择活动区域</a-select-option>
                        <a-select-option value="5000">5公里</a-select-option>
                        <a-select-option value="10000">10公里</a-select-option>
                        <a-select-option value="15000">15公里</a-select-option>
                        <a-select-option value="20000">20公里</a-select-option>
                        <a-select-option value="30000">30公里</a-select-option>
                        <a-select-option value="50000">50公里</a-select-option>
                        <a-select-option value="100000">100公里</a-select-option>
                        <a-select-option value="-1">不限制</a-select-option>
                    </a-select>
                </div>
                <baidu-map class="bm-view" ak="E8f3c743837925ce120eb93417f478db" :center="mapModel.center" :zoom="mapModel.zoom"
                           :scroll-wheel-zoom="true" @ready="mapReady">
                    <bm-marker :position="mapModel.center" :dragging="true" @dragend="dragend">
                    </bm-marker>
                    <bm-circle :center="circlePath.center" :radius="circlePath.radius" :editing="circlePath.editing" stroke-color="blue"
                               :stroke-opacity="0.5" :stroke-weight="2"></bm-circle>
                </baidu-map>
            </div>
            <div id="l-map" style="display:none;"></div>
        </a-modal>
    </div>
</template>
<script src="https://api.map.baidu.com/api?v=2.0&ak=E8f3c743837925ce120eb93417f478db"></script>
<script>
	import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    import Rich from "@/components/RichEditor.vue";
    import moment from "moment";
    import BaiduMap from "vue-baidu-map/components/map/Map.vue";
    import { BmCircle } from "vue-baidu-map";
    import { BmMarker } from "vue-baidu-map";
    export default {
        name: "",
        data() {
            return {
                disabled: false,
                rules: {
                    InvitationTitle: [
                        { message: "请输入互动游戏名称", required: true, trigger: "blur" },
                    ],
                    BeginTime: [
                        { message: "请输入开始时间", required: true, trigger: "change" },
                    ],
                    EndTime: [
                        { message: "请输入结束时间", required: true, trigger: "change" },
                    ],
                    InvitationRule: [
                        { message: "请输入互动游戏规则", required: true, trigger: "blur" },
                    ],
                },
                FormModel: {
                    FissionShareType: 0,
                    InviteIntroducerMoney: "",
                    InviteSharerMoney:0,
                    InvitationTitle: "",
                    BeginTime: "",
                    EndTime: "",
                    TargetUseType: '',
                    InvitationRule: "",
                    mapChecked: false,
                    InitialLuckDrawCount: "",
                    MaxWinningPrizeCount: "",
                    ruleChecked: false,
                    HelpLuckDrawCount: 0,
                    HelpMaxCount: 0,
                    GeographyLimit: "",
                    MapDistance: 5000,
                    Radius: "",
                    Lat: "36.062233",
                    Lng: "114.383176",
                },
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 5 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 19 },
                    },
                },
                //ID: 0,
                endOpen: false,
                Time: [],
                mapModelDisplay: false,
                circlePath: {
                    center: {
                        lng: 114.383176,
                        lat: 36.062233,
                    },
                    radius: "5000",
                    editing: false,
                },
                mapModel: {
                    center: { lng: 114.383176, lat: 36.062233 },
                    zoom: 13,
                    tempaddress: "河南省安阳市文峰区弦歌大道",
                },
                tempaddress: '',
                isloadac:0,
            };
        },
        props: {
            //组件属性
        },
        methods: {
            //方法

            gotonext: function () {
                var self = this;
                console.log("self.FormModel.InitialLuckDrawCount", self.FormModel.InitialLuckDrawCount);

                if (self.FormModel.mapChecked == true) {
                    self.FormModel.GeographyLimit = 1;
                }
                else {
                    self.FormModel.GeographyLimit = 0;
                }

                if (self.checkinput()) {
                    self.setlocal('InvitationTitle', self.FormModel.InvitationTitle);
                    self.setlocal('InvitationRule', self.FormModel.InvitationRule);
                    self.setlocal('StartTime', self.FormModel.BeginTime);
                    self.setlocal('EndTime', self.FormModel.EndTime);
                    self.setlocal('Lat', self.FormModel.Lat);
                    self.setlocal('Lng', self.FormModel.Lng);
                    self.setlocal('GeographyLimit', self.FormModel.GeographyLimit);
                    self.setlocal('MapDistance', self.circlePath.radius);
                    self.setlocal('MapDistanceStr', self.circlePath.radius);
                    self.setlocal('CenterPointAddress', self.mapModel.tempaddress);
                    self.setlocal('InitialLuckDrawCount', self.FormModel.InitialLuckDrawCount);
                    self.setlocal('MaxWinningPrizeCount', self.FormModel.MaxWinningPrizeCount);
                    self.setlocal('HelpLuckDrawCount', self.FormModel.HelpLuckDrawCount);
                    self.setlocal('HelpMaxCount', self.FormModel.HelpMaxCount);
                    self.setlocal('InviteIntroducerMoney', self.FormModel.InviteIntroducerMoney);
                    self.setlocal('InviteSharerMoney', self.FormModel.InviteSharerMoney);
                    self.setlocal('FissionShareType', self.FormModel.FissionShareType);

                    self.$router.push({
                        name: "InteractionConfig_AddInteractionAward",
                        query: { ID: self.ID, disabled: self.disabled }
                    });
                }
             
            },
         
            disabledStartDate(startValue) {
                const endValue = this.FormModel.EndTime;
                if (!startValue || !endValue) {
                    return false;
                }
                return startValue.valueOf() > endValue.valueOf();
            },
            handleStartOpenChange(open) {
                if (!open) {
                    this.endOpen = true;
                }
            },
            disabledEndDate(endValue) {
                const startValue = this.FormModel.BeginTime;
                if (!endValue || !startValue) {
                    return false;
                }
                return startValue.valueOf() >= endValue.valueOf();
            },
            handleEndOpenChange(open) {
                this.endOpen = open;
            },


            loadData: function () {
                var self = this;
                self.ID = self.$route.query.ID;
                var op = {
                    url: "/MarketingModule/Promote/GetOneTurnTableData",
                    data: {
                        TargetID: self.ID
                    },
                    OnSuccess: function (data) {
                        if (self.ID != 0) {
                            console.log(data.data.Data);
                            var obj = data.data.Data;
                            self.FormModel.InvitationTitle = obj.Name;
                            self.FormModel.InvitationRule = obj.TurnSummary;
                            self.FormModel.BeginTime = util.TimeStamptoDateTime(obj.BeginTime, "yyyy-MM-dd hh:mm:ss");
                            self.FormModel.EndTime = util.TimeStamptoDateTime(obj.EndTime, "yyyy-MM-dd hh:mm:ss");
                            self.Time = [
                                moment(self.FormModel.BeginTime, "YYYY-MM-DD"),
                                moment(self.FormModel.EndTime, "YYYY-MM-DD"),
                            ];
                            self.FormModel.GeographyLimit = obj.GeographyLimit;
                            self.FormModel.conterNum = obj.TurnSummary.length;
                            self.FormModel.inputnum = obj.Name.length;
                            self.FormModel.InitialLuckDrawCount = obj.InitialLuckDrawCount;
                            self.FormModel.MaxWinningPrizeCount = obj.MaxWinningPrizeCount;
                            self.FormModel.HelpLuckDrawCount = obj.HelpLuckDrawCount;
                            self.FormModel.HelpMaxCount = obj.HelpMaxCount;
                            self.FormModel.InviteIntroducerMoney = obj.InviteIntroducerMoney;
                            self.FormModel.InviteSharerMoney = obj.InviteSharerMoney;
                            self.FormModel.FissionShareType = obj.FissionShareType;
                            if (obj.HelpLuckDrawCount) {
                                self.FormModel.ruleChecked = true;
                            }
                            if (obj.GeographyLimit) {
                                self.FormModel.mapChecked = true;
                            }
                            self.mapModel.center.lat = obj.Lat;
                            self.mapModel.center.lng = obj.Lng;
                            self.mapModel.tempaddress = obj.CenterPointAddress;
                            //self.tempaddress = obj.CenterPointAddress;
                            self.MapDistance = obj.Radius * 1000;
                            //afterok && afterok();

                        }
                       
                    },
                };
                http.Post(op);
            },
         
            checkinput: function () {
                var rt = true;
                var self = this;
                if (self.FormModel.InvitationRule == "") {
                    rt = false;
                    self.$message.error("请输入互动使用规则");
                }
                if (self.FormModel.BeginTime == "") {
                    rt = false;
                    self.$message.error("请输入开始时间！");
                }
                if (self.FormModel.EndTime == "") {
                    rt = false;
                    self.$message.error("请输入结束时间！");
                }
                if (self.FormModel.InvitationTitle == "") {
                    rt = false;
                    self.$message.error("请输入互动名称！");
                }
                if (self.FormModel.InitialLuckDrawCount == "") {
                    rt = false;
                    self.$message.error("请抽奖次数");
                }
                if (self.FormModel.MaxWinningPrizeCount == "") {
                    rt = false;
                    self.$message.error("请输入中奖次数");
                }
                if (self.FormModel.InviteIntroducerMoney == "") {
                    rt = false;
                    self.$message.error("请输入邀请团队奖励");
                }
                return rt;
            },
         
            handleChange(value) {
                var self = this;
                console.log("提示：", value);
                self.Time = value;
                if (value.length == 0) {
                    self.FormModel.BeginTime = "";
                    self.FormModel.EndTime = "";
                } else {
                    self.FormModel.BeginTime = util.formatDate(self.Time[0]._d);
                    self.FormModel.EndTime = util.formatDate(self.Time[1]._d);
                    console.log(" self.FormModel.BeginTime", self.FormModel.BeginTime);
                    console.log(" self.FormModel.EndTime", self.FormModel.EndTime);

                }
            },
            setlocal: function (cname, cvalue) {
                localStorage.setItem(cname, cvalue);
            },
            getlocal: function (cname) {
                return localStorage.getItem(cname);
            },

            map_display: function () {
                var self = this;

                if (self.circlePath.radius == -1 || self.circlePath.radius == 0) {
                    self.FormModel.Radius = -1;
                } else {
                    self.FormModel.Radius = self.circlePath.radius / 1000;
                }
                self.FormModel.Lng = self.mapModel.center.lng;
                self.FormModel.Lat = self.mapModel.center.lat;
                self.FormModel.CenterPointAddress = self.mapModel.tempaddress;
                self.mapModelDisplay = true;
            },
            confirmAddress: function () {
                var self = this;
                self.FormModel.CenterPointAddress = self.mapModel.tempaddress;
                self.FormModel.Lng = self.mapModel.center.lng;
                self.FormModel.Lat = self.mapModel.center.lat;
                self.mapModel.tempaddress = self.tempaddress;
                console.log("提示：", "1");
                self.mapModelDisplay = false;
                //确认地理位置
            },
            radiusChange: function (value) {
                var self = this;
                console.log("提示：", value);
                self.circlePath.radius = value;
                if (value == -1 || value == 0) {
                    self.FormModel.Radius = -1;
                } else {
                    self.FormModel.Radius = value / 1000;
                }
            },
            mapReady: function ({ BMap, map }) {
                console.log(map);
                var self = this;
                self.gec = new BMap.Geocoder();
                self.FormModel.Lng = self.mapModel.center.lng;
                self.FormModel.Lat = self.mapModel.center.lat;
            },
            dragend: function (type) {
                var self = this;
                self.circlePath.center = type.point;
                self.FormModel.Lng = type.point.lng;
                self.FormModel.Lat = type.point.lat;
                self.gec.getLocation(type.point, function (rs) {
                    var addComp = rs.addressComponents;
                    var trainPlace =
                        addComp.province +
                        addComp.city +
                        addComp.district +
                        addComp.street +
                        addComp.streetNumber;
                    var content = trainPlace;
                    self.mapModel.tempaddress = content;
                    self.FormModel.CenterPointAddress = content;
                });
            },
        },
        computed: {
            //计算属性
        },
        watch: {
            //侦听属性
            "tempaddress": function () {
                console.log("dfjsa:", this.tempaddress);
                console.log("监听");
                var self = this;
                if (self.isloadac)
                    return 1;
                self.isloadac = 1;
                // 创建Map实例
                var map = new BMap.Map("l-map");
                console.log(map)
                var ac = new BMap.Autocomplete({
                    "input": 'suggestId',
                    "location": map
                })
                ac.addEventListener("onhighlight", function (e) {  //鼠标放在下拉列表上的事件
                    console.log("E:", e)

                    var str = "";
                    var _value = e.fromitem.value;
                    var value = "";
                    if (e.fromitem.index > -1) {
                        value = _value.province + _value.city + _value.district + _value.street + _value.business;
                    }
                    str = "FromItem<br />index = " + e.fromitem.index + "<br />value = " + value;

                    value = "";
                    if (e.toitem.index > -1) {
                        _value = e.toitem.value;
                        value = _value.province + _value.city + _value.district + _value.street + _value.business;
                    }
                    str += "<br />ToItem<br />index = " + e.toitem.index + "<br />value = " + value;
                    document.querySelector("#searchResultPanel").innerHTML = str;

                });

                var myValue;
                ac.addEventListener("onconfirm", function (e) {    //鼠标点击下拉列表后的事件
                    console.log('eeee', e);
                    var _value = e.item.value;
                    myValue = _value.province + _value.city + _value.district + _value.street + _value.business;
                    document.querySelector("#searchResultPanel").innerHTML = "onconfirm<br />index = " + e.item.index + "<br />myValue = " + myValue;
                    setPlace();
                    self.tempaddress = myValue;
                    console.log("获取地址信息：", e.currentTarget.ej);
                    console.log("现在地址：", self.mapModel.tempaddress);
                });
                function setPlace() {
                    map.clearOverlays();    //清除地图上所有覆盖物
                    function myFun() {

                        var pp = local.getResults().getPoi(0).point;    //获取第一个智能搜索的结果
                        console.log("pp", pp);
                        self.mapModel.center.lat = pp.lat;
                        self.mapModel.center.lng = pp.lng;
                        self.circlePath.center.lat = pp.lat;
                        self.circlePath.center.lng = pp.lng;
                        console.log(self.mapModel.center);
                        var value = 10000;
                        self.radiusChange(value);
                        //map.centerAndZoom(pp, 18);
                        //map.addOverlay(new BMap.Marker(pp));
                        //添加标注
                    }
                    var local = new BMap.LocalSearch(map, { //智能搜索
                        onSearchComplete: myFun
                    });

                    local.search(myValue);

                }

            }
        },
        components: {
            //组件
            Rich,
            BaiduMap,
            BmCircle,
            BmMarker,
        },
        beforeCreate: function () {
            //生命周期，创建前
        },
        created: function () {
            //生命周期，创建完成
        },
        beforeMount: function () {
            //生命周期，组装前
        },
        mounted: function () {
            //生命周期，组装完成
            var self = this;
            if (self.$route.query.disabled == 1) {
                self.disabled = true;
            }
            self.ID = self.$route.query.ID;
            self.loadData();

        },
        beforeUpdate: function () {
            //生命周期，data更新前
        },
        updated: function () {
            //生命周期，data更新完成
        },
        beforeDestroy: function () {
            //生命周期，销毁前
        },
        destroyed: function () {
            //生命周期，销毁完成
        },
    };
</script>
<style type="text/css">
    .bm-view {
        width: 100%;
        height: 600px;
    }

    #suggestId {
        width: 500px;
        top: -68px;
        margin-left: 100px;
    }

    #map_title {
        position: absolute;
        z-index: 9999;
        left: 23px;
        right: 23px;
        height: 60px;
        background-color: #09f;
        padding-top: 10px;
        padding-left: 20px;
        color: white;
    }

    .tangram-suggestion {
        z-index: 199999
    }

    #map_sel {
        position: absolute;
        z-index: 9999;
        right: 106px;
        top: 92px;
    }

    #map_btns {
        position: absolute;
        z-index: 9999;
        bottom: 30px;
        width: 100%;
    }

        #map_btns input[type='button'] {
            width: 120px;
            height: 45px;
            margin-top: 20px;
            color: #fff;
            border: none;
            border-radius: 30px;
            opacity: 0.8;
        }

    .map_save {
        background-color: green;
        margin-right: 40px;
    }

    .map_cancel {
        background-color: #f15b5b;
    }

    .solid-btn {
        background: #32a9ff;
        color: #fff !important;
        padding: 4px 18px;
        border: none;
        border-radius: 2px;
        transition: all .15s ease-in-out;
        margin-top: -3px;
    }

    .yj-storecon-template > div {
        height: 100px;
        width: 100px;
        overflow: hidden;
        text-align: center;
        flex-shrink: 0;
    }
</style>
